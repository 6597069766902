<template>
    <div class="main-contents display terms ncs">
        <div class="tit">능력단위요소 등록/수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
        <div class="sub-tit" v-if="this.abilElemSeq == ''"><p>능력단위요소 등록</p></div>
        <div class="sub-tit" v-else ><p>능력단위요소 수정</p></div>
        <div class="banner_box unit">
            <div class="list">
                <div class="label">중분류명</div>

                <div v-if="this.abilElemSeq == ''">
                    <SelectComp :list="midNameList" codeKey="midClassCd" nameKey="midClassNm" title="중분류" v-model="elemDetail.midClassCd" @change="loadMinor()" :rules="{'required':true}" maxByte="4" />
                </div>
                <div v-else>
                    <SelectComp :list="midNameList" codeKey="midClassCd" nameKey="midClassNm" title="중분류" v-model="elemDetail.midClassCd" :disabled="abilElemSeq != ''" />
                </div>

                <div class="label unit">능력단위명</div>

                <div v-if="this.abilElemSeq == ''">
                    <SelectComp :list="abilNameList" codeKey="abilUnitCd" nameKey="abilUnitNm" title="능력단위명" v-model="elemDetail.abilUnitCd" :rules="{'required':true}" maxByte="30" />
                </div>
                <div v-else>
                    <SelectComp :list="abilNameList" codeKey="abilUnitCd" nameKey="abilUnitNm" title="능력단위명" v-model="elemDetail.abilUnitCd" :disabled="abilElemSeq != ''" />
                </div>

                <div class="label">사용여부</div>
                <SelectComp list="Y:사용,N:사용안함" v-model="elemDetail.useYn" class="wd01" title="사용여부" :rules="{'required':true}" maxByte="1" />
            </div>
            <div class="list">
            <div class="label">소분류명</div>
                <div v-if="this.abilElemSeq == ''">
                    <SelectComp :list="minorNameList" codeKey="minorClassCd" nameKey="minorClassNm" v-model="elemDetail.minorClassCd" title="소분류" @change="loadDetail()" :rules="{'required':true}" maxByte="6" />
                </div>
                <div v-else>
                    <SelectComp :list="minorNameList" codeKey="minorClassCd" nameKey="minorClassNm" v-model="elemDetail.minorClassCd" :disabled="abilElemSeq != ''" />
                </div>
                <div class="label unit">능력단위요소코드</div><InputComp type="text" placeholder="능력단위요소코드" v-model="elemDetail.abilUnitElemCd" :rules="{'required':true}" tagName="div" title="능력단위요소코드" maxByte="30" />
            </div>
            <div class="list">
                <div class="label">세분류명</div>
                <div v-if="this.abilElemSeq == ''">
                    <SelectComp :list="detailNameList" codeKey="detailClassCd" nameKey="detailClassNm" title="세분류" v-model="elemDetail.detailClassCd" @change="loadAbil()" :rules="{'required':true}" maxByte="8" />
                </div>
                <div v-else>
                    <SelectComp :list="detailNameList" codeKey="detailClassCd" nameKey="detailClassNm" v-model="elemDetail.detailClassCd" :disabled="abilElemSeq != ''" />
                </div>
                <div class="label unit">능력단위요소명</div><InputComp type="text" placeholder="능력단위요소명" v-model="elemDetail.abilUnitElemNm" :rules="{'required':true}" tagName="div" title="능력단위요소명" maxByte="100" />
            </div>
        </div>
        <div class="select_btn">
            <div class="btn_01" @click="movePage('back')">취소</div>
            <div class="btn_02" v-if="this.abilElemSeq == ''" @click="openPopup()">등록</div>
            <div class="btn_02" v-else style="background-color: #FF681D;" @click="openPopup()">수정</div>
        </div>
    </div>
</template>

<script>
import SelectComp from "@/components/base/SelectComp.vue";
import InputComp from "@/components/base/InputComp.vue";
export default {
    data() {
        return {
            elemDetail: {
                midClassCd: "",
                minorClassCd: "",
                detailClassCd: "",
                abilUnitCd: "",
                abilUnitElemCd: "",
                abilUnitElemNm: "",
                useYn: "",
                abilElemSeq: "",
            },
            abilElemSeq: this.$route.params.abilElemSeq || "",
            midNameList: [],
            minorNameList: [],
            detailNameList: [],
            abilNameList: [],
        };
    },
    beforeMount() {
        this.loadMid();
        if (this.abilElemSeq != "") {
            this.loadMinor();
            this.loadDetail();
            this.loadAbil();
            this.getAbilElemInfo();
            this.elemDetail.abilElemSeq = this.abilElemSeq;
        }
    },
    methods: {
        getAbilElemInfo() {
            var param = {};
            param.abilElemSeq = this.abilElemSeq;
            this.$.httpPost("/api/main/adm/ncsinout/getAbilUnitElemInfo", { abilElemSeq: this.abilElemSeq })
                .then(res => {
                // console.log("getAbilUnitElemInfo Result", res);
                this.elemDetail = res.data.abilElemInfo || {};
                // console.log("real?", this.elemDetail);
            }).catch(this.$.httpErrorCommon);
        },
        movePage(div) {
            if (div == "back")
                this.$router.go(-1);
            else
                this.$router.push("MAN936M04");
        },
        loadMid() {
            this.$.httpPost("/api/main/adm/ncsinout/loadMidList")
                .then(res => {
                // console.log("loadMidList RESULT", res);
                this.midNameList = res.data.loadMidList;
            }).catch(this.$.httpErrorCommon);
        },
        loadMinor() {
            this.minorNameList = [];
            this.elemDetail.minorClassCd = "";
            this.detailNameList = [];
            this.elemDetail.detailClassCd = "";
            this.abilNameList = [];
            this.elemDetail.abilUnitCd = "";
            var params = {};
            params.midClassCd = this.elemDetail.midClassCd;
            this.$.httpPost("/api/main/adm/ncsinout/loadMinorList", params)
                .then(res => {
                // console.log("loadMinorList RESULT", res);
                // console.log(22222);
                // console.log(res);
                this.minorNameList = res.data.loadMinorList;
            }).catch(this.$.httpErrorCommon);
        },
        loadDetail() {
            this.detailNameList = [];
            this.elemDetail.detailClassCd = "";
            this.abilNameList = [];
            this.elemDetail.abilUnitCd = "";
            var params = {};
            params.minorClassCd = this.elemDetail.minorClassCd;
            this.$.httpPost("/api/main/adm/ncsinout/loadDetailList", params)
                .then(res => {
                // console.log("loadDetailList RESULT", res);
                this.detailNameList = res.data.loadDetailList;
            }).catch(this.$.httpErrorCommon);
        },
        loadAbil() {
            this.abilNameList = [];
            this.elemDetail.abilUnitCd = "";
            var params = {};
            params.detailClassCd = this.elemDetail.detailClassCd;
            this.$.httpPost("/api/main/adm/ncsinout/loadAbilList", params)
                .then(res => {
                // console.log("loadAbilList RESULT", res);
                this.abilNameList = res.data.loadAbilList;
            }).catch(this.$.httpErrorCommon);
        },

        openPopup() {
            var param = {};
            param.abilElemSeq = this.elemDetail.abilUnitCd;
            param.elemDetail = this.elemDetail;

            this.$.popup('/adm/man/MAN936P02', param)
                .then(res => {
                    if(res) {
                        this.$.httpPost('/api/main/adm/ncsinout/setAbilUnitElem', this.elemDetail)
                            .then(() => {
                                this.movePage();
                            }).catch(this.$.httpErrorCommon);
                    }
                })
        },
    },
    components: { SelectComp, InputComp }
}
</script>